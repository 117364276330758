import { DeleteOutlined } from '@ant-design/icons';
import { IBedrockEnrollment } from '@shared/bedrock';
import { UserToken } from '@shared/types';
import { InlineInput } from '@web/1on1s/InlineInput';
import { UserAvatar } from '@web/components/UserAvatar';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sortBy } from 'lodash';
import * as React from 'react';

export const EnrollmentsTable: React.FC<{
  enrollments: IBedrockEnrollment[];
  onEditGithub?: (userToken: UserToken, githubLogin: string) => void;
  onDelete: (enrollment: IBedrockEnrollment) => void;
}> = ({ enrollments, onEditGithub, onDelete }) => {
  const columns: ColumnsType<IBedrockEnrollment> = [
    {
      dataIndex: ['user', 'slackImageUrl'],
      width: 50,
      render(_, enrollment) {
        return <UserAvatar user={enrollment.user} size={24} />;
      },
    },
    {
      dataIndex: ['user', 'name'],
      title: 'Name',
    },
    {
      dataIndex: ['user', 'githubLogin'],
      title: 'GitHub Username',
      render: (_, enrollment) => (
        <EditableGithubLoginCell
          enrollment={enrollment}
          onSave={(githubLogin: string) => {
            onEditGithub(enrollment.user.token, githubLogin);
          }}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, enrollment) => {
        return (
          <Button
            type="text"
            onClick={() => {
              onDelete(enrollment);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const sortedEnrollments = sortBy(enrollments, 'user.name');
  return (
    <Table rowKey="token" columns={columns} dataSource={sortedEnrollments} />
  );
};

const EditableGithubLoginCell: React.FC<{
  enrollment: IBedrockEnrollment;
  onSave: (githubLogin: string) => void;
}> = ({ enrollment, onSave }) => {
  const [githubLogin, setGithubLogin] = React.useState(
    enrollment.user.githubLogin ?? '',
  );

  const handleSave = () => {
    onSave(githubLogin);
  };
  return (
    <InlineInput
      value={githubLogin}
      onChange={setGithubLogin}
      onEnter={() => {}}
      onBlur={handleSave}
    />
  );
};
