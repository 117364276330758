import {
  CreateTemplatedFeedbackRequest,
  FeedbackTemplateToken,
  findTemplateByToken,
  managerFeedbackRequestTemplates,
} from '@shared/feedbackTemplates';
import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { FormButtons } from '@web/components/FormButtons';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Spacer } from '@web/components/layout';
import { SelectUser } from '@web/components/users/SelectUser';
import { Button, Form, Input, Select, Typography, message } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TemplatedFeedbackForm } from './TemplatedFeedbackForm';

const { Option } = Select;

interface RequestFeedbackFields {
  giverToken: UserToken;
  receiverToken: UserToken;
  template: FeedbackTemplateToken;
  parameters: Record<string, string>;
}

interface ParameterUpdateAction {
  parameterName: string;
  newValue: string;
}

export const RequestFeedbackTemplatePage: React.FC<{
  forReceiver?: UserToken;
}> = ({ forReceiver }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm<RequestFeedbackFields>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { userToken } = useParams<{ userToken?: string }>();
  const implicitReceiverToken = forReceiver ?? userToken;
  const { data: implicitReceiver } = useApi<IUser>(
    implicitReceiverToken ? `/users/${implicitReceiverToken}` : null,
  );
  const templateToken = Form.useWatch('template', form);
  const receiverToken = Form.useWatch('receiverToken', form);
  const giverToken = Form.useWatch('giverToken', form);

  const feedbackRequestTemplates = managerFeedbackRequestTemplates;

  const [template, setTemplate] = React.useState(
    feedbackRequestTemplates.filter((template) => !template.disabled)[0],
  );

  const paramReducer = (
    parameters: Record<string, string>,
    action: ParameterUpdateAction,
  ) => {
    const newParameters = { ...parameters };
    newParameters[action.parameterName] = action.newValue;
    return newParameters;
  };
  const [parameters, dispatch] = React.useReducer(paramReducer, {});

  const dispatchReceiver = (user: IUser) => {
    dispatch({
      parameterName: 'receiver',
      newValue: user.name,
    });
  };

  useEffect(() => {
    dispatch({
      parameterName: 'requester',
      newValue: user.name,
    });
  }, [user]);

  useEffect(() => {
    if (implicitReceiver) {
      dispatchReceiver(implicitReceiver);
    }
  }, [implicitReceiver]);

  useEffect(() => {
    const newTemplate = findTemplateByToken(templateToken);
    if (!newTemplate) {
      return;
    }
    setTemplate(newTemplate);
  }, [templateToken]);

  if (!template || (userToken && !implicitReceiver)) {
    return null;
  }

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    let fields: RequestFeedbackFields;
    try {
      fields = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      await post<CreateTemplatedFeedbackRequest>('/feedback/request/template', {
        receiverToken: implicitReceiver?.token ?? fields.receiverToken,
        giverToken: fields.giverToken,
        questionSetToken: fields.template,
        templateParameters: fields.parameters,
      });
      void message.success('Success');
      form.resetFields();
      goBack();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <PageContent>
      <PageHeader
        title={'Request feedback'}
        navigateBack
        defaultNavigateBackTo="/team/members"
      />
      <Pane>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            shareWithManager: true,
            template: template.token,
          }}
        >
          {implicitReceiver ? (
            implicitReceiver.token !== user.token ? (
              <UserMessage
                user={implicitReceiver}
                style={{ marginBottom: 24 }}
              />
            ) : null
          ) : (
            <Form.Item
              label="Who would you like to collect feedback about?"
              name="receiverToken"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <SelectUser
                disabled={isSaving}
                omitUserTokens={[user.token, giverToken]}
                reportsOnly={true}
                onChange={(_, user) => {
                  dispatchReceiver(user);
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Who would you like to request feedback from?"
            name="giverToken"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <SelectUser
              disabled={isSaving}
              omitUserTokens={[
                user.token,
                implicitReceiver?.token ?? receiverToken,
              ]}
            />
          </Form.Item>
          <Form.Item
            name="template"
            label="Select a template for this feedback request"
          >
            <Select>
              {feedbackRequestTemplates
                .filter((template) => !template.disabled)
                .map((template) => {
                  return (
                    <Option key={template.token} value={template.token}>
                      {template.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {template?.templateParameters?.map((question) => (
            <Form.Item
              key={question.token}
              label={question.text}
              name={['parameters', question.parameterName]}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                disabled={isSaving}
                onChange={(e) => {
                  dispatch({
                    parameterName: question.parameterName,
                    newValue: e.target.value,
                  });
                }}
              />
            </Form.Item>
          ))}
          <Typography.Text style={{ fontStyle: 'italic' }}>
            Note: Responses to feedback requests will only be visible to you.
          </Typography.Text>
          <Spacer size={12} />
          <FormButtons>
            <Button type="primary" onClick={handleSubmit}>
              Submit request
            </Button>
            <Button onClick={goBack}>Cancel</Button>
          </FormButtons>
        </Form>
      </Pane>
      <Spacer />
      <Column>
        <Typography.Title level={5} style={{ fontWeight: 400 }}>
          Request Preview
        </Typography.Title>
        <Pane>
          <TemplatedFeedbackForm
            template={template}
            parameters={parameters}
            disabled={true}
            isViewing={true}
          />
        </Pane>
      </Column>
    </PageContent>
  );
};
