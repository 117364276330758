import { ImpactEntryMap } from '@shared/impacts';
import { FeedbackType, IFeedback, UserMap, isImpactToken } from '@shared/types';
import * as React from 'react';

import { BasicFeedback } from './BasicFeedback';
import { DimensionFeedback } from './DimensionFeedback';
import { EntryUserFeedback } from './EntryUserFeedback';
import { TemplatedUserFeedback } from './TemplatedUserFeedback';

interface IViewFeedbackProps {
  feedback: IFeedback;
  userMap: UserMap;
  impactEntryMap?: ImpactEntryMap;
}
export const ViewFeedback: React.FC<IViewFeedbackProps> = ({
  feedback,
  userMap,
  impactEntryMap,
}) => {
  const feedbackType = feedback.type;
  const giver = userMap[feedback.giverToken];
  const receiver = userMap[feedback.receiverToken];
  const requesterToken = feedback.feedbackRequest?.requesterToken;
  const requester = requesterToken ? userMap[requesterToken] : undefined;
  const impactEntry = isImpactToken(feedback.entityToken)
    ? feedback.impact ?? impactEntryMap?.[feedback.entityToken]
    : undefined;

  if (!receiver || !giver) {
    return null;
  }

  if (feedbackType === FeedbackType.TEMPLATE) {
    return (
      <TemplatedUserFeedback
        feedback={feedback}
        giver={giver}
        receiver={receiver}
        requester={requester}
      />
    );
  } else if (feedbackType === FeedbackType.DIMENSION) {
    return (
      <DimensionFeedback
        feedback={feedback}
        giver={giver}
        receiver={receiver}
      />
    );
  } else if (impactEntry) {
    return (
      <EntryUserFeedback
        feedback={feedback}
        giver={giver}
        receiver={receiver}
        impactEntry={impactEntry}
      />
    );
  }

  return (
    <BasicFeedback feedback={feedback} giver={giver} receiver={receiver} />
  );
};
