import { OrganizationToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Modal } from 'antd';
import * as React from 'react';

import { SelectChannels } from './SelectChannels';

export const AddChannelsModal: React.FC<{
  organizationToken: OrganizationToken;
  onAdd: (channelIds: string[]) => Promise<void>;
  onCancel: () => void;
}> = ({ organizationToken, onAdd, onCancel }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [channelIds, setChannelIds] = React.useState<string[]>();

  const handleOk = async () => {
    setIsSaving(true);
    try {
      await onAdd(channelIds);
    } finally {
      setIsSaving(false);
    }
  };
  const handleClose = () => {
    setIsSaving(false);
    onCancel();
  };
  return (
    <Modal
      title="Add Channels"
      open={true}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="500px"
      okText="Save"
    >
      <Column gap={12}>
        <Text>Channels</Text>
        <SelectChannels
          organizationToken={organizationToken}
          excludeChannelIds={[]}
          onChange={setChannelIds}
        />
      </Column>
    </Modal>
  );
};
