import {
  GetBedrockConfigurationResponse,
  IBedrockEnrollment,
  RunBedrockRequest,
} from '@shared/bedrock';
import { OrganizationToken, UserToken } from '@shared/types';
import { del, get, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function runBedrock(
  organizationToken: OrganizationToken,
  request: RunBedrockRequest,
) {
  return post<RunBedrockRequest>(
    `/bedrock-configuration/organization/${organizationToken}/run`,
    request,
  );
}

export function generate(
  organizationToken: OrganizationToken,
  userToken: UserToken,
  mechanism: 'journal' | 'dm',
) {
  return post<{}>(
    `/bedrock-configuration/generate/${organizationToken}/${userToken}/${mechanism}`,
  );
}

export function useEnrolledBedrockUsersInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled`,
    query: {},
  });

  return useApi<IBedrockEnrollment[]>(url);
}

export function enrolledBedrockUsersInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled`,
    query: {},
  });

  return get<IBedrockEnrollment[]>(url);
}

export function useEnrolledBedrockUsers() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled`,
    query: {},
  });

  return useApi<IBedrockEnrollment[]>(url);
}

export function enrolledBedrockUsers() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled`,
    query: {},
  });

  return get<IBedrockEnrollment[]>(url);
}

export function useBedrockConfigurationInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/configuration`,
    query: {},
  });

  return useApi<GetBedrockConfigurationResponse>(url);
}

export function bedrockConfigurationInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/configuration`,
    query: {},
  });

  return get<GetBedrockConfigurationResponse>(url);
}

export function useBedrockEligibleChannels(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/channels`,
    query: {},
  });

  return useApi<{ name: string; id: string }[]>(url);
}

export function bedrockEligibleChannels(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/channels`,
    query: {},
  });

  return get<{ name: string; id: string }[]>(url);
}

export function addBedrockChannels(
  organizationToken: OrganizationToken,
  channelIds: string[],
) {
  return post<{ channelIds: string[] }, void>(
    `/bedrock-configuration/organization/${organizationToken}/channels`,
    { channelIds: channelIds },
  );
}

export function deleteBedrockChannel(
  organizationToken: OrganizationToken,
  channelId: string,
) {
  return del(
    `/bedrock-configuration/organization/${organizationToken}/channels/${channelId}`,
  );
}

export function enrollBedrockUsersInternal(
  organizationToken: OrganizationToken,
  userToken: UserToken,
) {
  return post<{ userToken: `u_${string}` }>(
    `/bedrock-configuration/organization/${organizationToken}/enroll`,
    { userToken: userToken },
  );
}

export function enrollBedrockUser(userToken: UserToken) {
  return post<{ userToken: `u_${string}` }>(`/bedrock-configuration/enroll`, {
    userToken: userToken,
  });
}

export function deleteEnrolledBedrockUser(userToken: UserToken) {
  return del(`/bedrock-configuration/enrolled/${userToken}`);
}

export function deleteEnrolledBedrockUserInternal(
  userToken: UserToken,
  organizationToken: OrganizationToken,
) {
  return del(
    `/bedrock-configuration/organization/${organizationToken}/enrolled/${userToken}`,
  );
}
