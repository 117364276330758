import { IQuestion, isRankQuestion, isRatingQuestion } from '@shared/questions';
import { IReflectionResponse } from '@shared/reflections';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { ActionBar } from '@web/comments/ActionBar';
import { CommentsList } from '@web/comments/CommentsList';
import { Column, Grid, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { renderReceiverQuestionText } from '@web/questions/renderReceiverQuestionText';
import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

export const ReflectionResponseList: React.FC<{
  questions: IQuestion[];
  responses: IReflectionResponse[];
  receiverName?: string;
  allowReply?: boolean;
}> = ({ questions, responses, receiverName, allowReply = false }) => {
  const answered = responses.filter(
    (r) => !!r.content || !!r.rating || !!r.ranking,
  );

  const sortedResponses = sortBy(answered, (response) =>
    questions.findIndex((q) => q.token === response.questionToken),
  );
  return (
    <Column style={{ gap: 10 }}>
      {sortedResponses.map((response) => (
        <Column key={response.token} gap={6}>
          <ReflectionResponseItem
            response={response}
            receiverName={receiverName}
            allowReply={allowReply}
          />
        </Column>
      ))}
    </Column>
  );
};

export const ReflectionResponseItem: React.FC<{
  response: IReflectionResponse;
  receiverName?: string;
  allowReply: boolean;
}> = ({ response, receiverName, allowReply }) => {
  const [showNewComment, setShowNewComment] = React.useState(false);
  const question = response.question;
  let responseSummary = (
    <Text style={{ whiteSpace: 'pre-wrap' }}>{response.content}</Text>
  );
  if (isRatingQuestion(question)) {
    responseSummary = (
      <Column>
        {question.scale?.map((rating) => (
          <RatingResponseRow
            key={`rating_option_${rating.value}`}
            selected={response?.rating === rating.value}
          >
            <Text
              style={
                response?.rating === rating.value
                  ? { color: complimentaryColor.darken(0.5) }
                  : undefined
              }
            >
              {rating.label}
            </Text>
          </RatingResponseRow>
        ))}
      </Column>
    );
  } else if (isRankQuestion(question)) {
    responseSummary = (
      <Column gap={6}>
        {question.rankingOptions.map((option) => (
          <Grid
            key={`rank_option_${option.token}`}
            columns="330px 1fr"
            gap={12}
            style={{ margin: '0 0 0 24px' }}
          >
            <Text
              style={{
                lineHeight: '20px',
                alignSelf: 'center',
              }}
            >
              {option.label}
            </Text>
            <Text style={{ textAlign: 'center', width: 40 }}>
              {response.ranking[option.token]}
            </Text>
          </Grid>
        ))}
      </Column>
    );
  }
  return (
    <Column gap={5}>
      <Header3 style={{ whiteSpace: 'pre-wrap' }}>
        {renderReceiverQuestionText(question, receiverName)}
      </Header3>
      {responseSummary}
      {allowReply && (
        <ActionBar
          onReply={() => {
            setShowNewComment(true);
          }}
          entityToken={response.token}
        />
      )}
      <CommentsList
        entityToken={response.token}
        showNewComment={showNewComment}
        onHideNewComment={() => {
          setShowNewComment(false);
        }}
      />
    </Column>
  );
};

const RatingResponseRow = styled(Row)<{ selected?: boolean }>`
  padding: 6px 12px;
  gap: 6px;
  border: 1px solid transparent;

  ${(props) =>
    props.selected
      ? `background-color: ${complimentaryColor.lighten(
          0.9,
        )}; border-color: ${complimentaryColor.lighten(0.6)};`
      : 'unset'}
  border-radius: var(--default-border-radius);
  max-width: 500px;
`;
