import { PlusOutlined } from '@ant-design/icons';
import {
  deleteEnrolledBedrockUser,
  enrollBedrockUser,
  useEnrolledBedrockUsers,
} from '@client/BedrockConfigurationClient';
import { update as updateUser } from '@client/UsersClient';
import { IBedrockEnrollment } from '@shared/bedrock';
import { UserToken } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { EnrollUsersModal } from '@web/bedrock/EnrollUsersModal';
import { EnrollmentsTable } from '@web/bedrock/EnrollmentsTable';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button, Skeleton, message } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { AdminPageContent } from './AdminPageContent';

export const AdminBedrockPage: React.FC = () => {
  const { user } = useAuth();
  const { data: enrollments, mutate: reloadEnrollments } =
    useEnrolledBedrockUsers();
  const [showEnrollModal, setShowEnrollModal] = React.useState(false);

  const handleEnroll = async (userToken: UserToken) => {
    try {
      await enrollBedrockUser(userToken);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to enroll user');
    } finally {
      setShowEnrollModal(false);
    }
  };

  const handleDelete = async (enrollment: IBedrockEnrollment) => {
    try {
      await deleteEnrolledBedrockUser(enrollment.user.token);
      await reloadEnrollments();
      void message.success('Success');
    } catch (error) {
      void message.error('Failed to remove user');
    }
  };

  if (!user) {
    <AdminPageContent>
      <Skeleton />
    </AdminPageContent>;
  }
  return (
    <AdminPageContent>
      <Row gap={6}>
        <GrowingSpacer />
        <Button
          type="primary"
          onClick={() => {
            setShowEnrollModal(true);
          }}
          style={{ maxWidth: 200 }}
        >
          <PlusOutlined /> Enroll Users
        </Button>
      </Row>
      {enrollments ? (
        <EnrollmentsTable
          enrollments={enrollments}
          onDelete={handleDelete}
          onEditGithub={async (userToken: UserToken, githubLogin: string) => {
            await updateUser(userToken, {
              githubLogin: isEmpty(githubLogin) ? null : githubLogin.trim(),
            });
          }}
        />
      ) : (
        <Skeleton />
      )}
      {showEnrollModal && (
        <EnrollUsersModal
          organizationToken={user.organizationToken}
          omitUserTokens={enrollments?.map(
            (enrollment) => enrollment.user.token,
          )}
          onCancel={() => {
            setShowEnrollModal(false);
          }}
          onEnroll={handleEnroll}
        />
      )}
    </AdminPageContent>
  );
};
