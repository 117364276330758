import { QuestionType } from './QuestionType';
import { exhaustiveCheck } from './exhaustiveCheck';
import { IImpact, ImpactToken } from './impacts';
import { IQuestion, IRankOption, QuestionToken } from './questions';
import { RatingScale, createScale } from './rating_scales';
import { EntityReactionSummaryMap } from './reactions';
import { IReviewCycle, ReviewCycleToken } from './review-cycles';
import { ITag, TagToken } from './tags';
import {
  IEntity,
  ITask,
  IUser,
  OrganizationToken,
  UserMap,
  UserToken,
} from './types';

export type ReflectionToken = `r_${string}`;

export enum ReflectionState {
  DRAFT = 'DRAFT',
  COMPLETE = 'COMPLETE',
  SHARED = 'SHARED',
}

export interface NewReflectionRequest {
  receiverToken: UserToken;
  title: string;
  accomplishmentTokens: ImpactToken[];
  textContext: string;
  privateNote: string;
  tags: TagToken[];
  startDate: Date;
  endDate: Date;
  responses: ReflectionResponseInput[];
}

export interface NewReflectionResponse {
  reflection: IReflection;
}

export interface IReflection extends IEntity {
  token: ReflectionToken;
  organizationToken: OrganizationToken;
  title: string;
  receiverToken: UserToken;
  receiver: IUser;
  authorToken: UserToken;
  author: IUser;
  privateNote: string;
  accomplishments: IImpact[];
  textContext: string;
  tags: ITag[];
  state: ReflectionState;
  startDate: Date;
  endDate: Date;
  responses: IReflectionResponse[];
  completedDate?: Date;
  sharedDate?: Date;
  questionSetToken?: ReflectionQuestionSetToken;
  includeWork: boolean;
  includePrivateNote: boolean;
  reviewCycleToken?: ReviewCycleToken;
  reviewCycle?: IReviewCycle;
  task?: ITask;
  productName: ProductName;
  canEdit: boolean;
}

export enum ProductName {
  REVIEW = 'REVIEW',
  REFLECTION = 'REFLECTION',
}

export interface IProductDetails {
  titleCase: string;
  lowerCase: string;
  preposition: string;
}
export const productDetails = (productName: ProductName): IProductDetails => {
  switch (productName) {
    case ProductName.REFLECTION:
      return {
        titleCase: 'Reflection',
        lowerCase: 'reflection',
        preposition: 'on',
      };
    case ProductName.REVIEW:
      return {
        titleCase: 'Review',
        lowerCase: 'review',
        preposition: 'of',
      };
    default:
      exhaustiveCheck(productName);
  }

  throw new Error(`Invalid productName ${productName as any}`);
};

export interface GetReflectionResponse {
  reflection: IReflection;
  reactions: {
    reactionSummaryMap: EntityReactionSummaryMap;
    userMap: UserMap;
  };
}

export interface ListReflectionsResponse {
  reflections: IReflection[];
}

export interface UpdateReflectionRequest
  extends Omit<
    NewReflectionRequest,
    'receiverToken' | 'accomplishmentTokens' | 'startDate' | 'endDate' | 'title'
  > {
  draft: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateReflectionResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteReflectionResponse {}

export function isReflectionToken(token: string): token is ReflectionToken {
  return !!token?.startsWith('r_');
}

export type ReflectionResponseToken = `rr_${string}`;
export interface IReflectionResponse extends IEntity {
  token: ReflectionResponseToken;
  organizationToken: OrganizationToken;
  questionToken: QuestionToken;
  question: IQuestion;
  content?: string;
  rating?: number;
  ranking?: Record<IRankOption['token'], number>;
}

export type ReflectionResponseInput =
  | ReflectionResponseRatingInput
  | ReflectionResponseTextInput
  | ReflectionResponseRankInput;

interface BaseReflectionResponseInput {
  type: string;
  questionToken: QuestionToken;
  question: IQuestion;
}

export interface ReflectionResponseRatingInput
  extends BaseReflectionResponseInput {
  type: 'rating';
  rating: number;
}

export function isRatingResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseRatingInput {
  return response?.type === 'rating';
}

export interface ReflectionResponseTextInput
  extends BaseReflectionResponseInput {
  type: 'text';
  content: string;
}

export interface ReflectionResponseRankInput
  extends BaseReflectionResponseInput {
  type: 'rank';
  ranking: Record<IRankOption['token'], number>;
}

export function isTextResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseTextInput {
  return response?.type === 'text';
}

export function isRankResponse(
  response?: ReflectionResponseInput,
): response is ReflectionResponseRankInput {
  return response?.type === 'rank';
}

export const REFLECTION_EXPECTATIONS_QUESTION_TOKEN =
  'q_reflection_expectations';
export const REFLECTION_PROGRESS_QUESTION_TOKEN = 'q_reflection_progress';
export const REFLECTION_ADDITIONAL_THOUGHTS_QUESTION_TOKEN =
  'q_reflection_additional_thoughts';

export interface IReflectionConfiguration {
  enabled: boolean;
  enrolled: IUser[];
  schedule: IReflectionScheduleEvent[];
}

export type ReflectionConfigurationToken = `rc_${string}`;

export interface IReflectionScheduleEvent {
  token: ReflectionScheduleEventToken;
  title: string;
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  draftDate: Date;
  draftCreatedDate: Date;
  taskDate: Date;
  taskCreatedDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateScheduledEventResponse {}

export interface UpdateScheduledEventRequest {
  title: string;
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  draftDate: Date;
  taskDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RemoveEnrolledManagerResponse {}

export interface AddEnrolledManagerRequest {
  userToken: UserToken;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddEnrolledManagerResponse {}

export interface SetReflectionsEnabledRequest {
  enabled: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetReflectionsEnabledResponse {}

export interface GetReflectionConfigurationResponse {
  enabled: boolean;
  enrolled: IUser[];
  schedule: IReflectionScheduleEvent[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateNextReflectionEventResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SendReflectionDraftsResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SendReflectionTasksResponse {}

export type ReflectionScheduleEventToken = `rse_${string}`;

export type ReflectionEnrollmentToken = `re_${string}`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateSelfReflectionRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateSelfReflectionResponse {}

export type ReflectionQuestionSetToken = `rqs_${string}`;

export interface IReflectionQuestionSet {
  token: ReflectionQuestionSetToken;
  name: string;
  questions: Array<
    IQuestion & {
      counterpartQuestionToken?: QuestionToken;
    }
  >;
}

export const findReflectionQuestionSet = (token: ReflectionQuestionSetToken) =>
  reflectionTemplates.find((rqs) => rqs.token === token);

const ProviviPerformanceScale: RatingScale = [
  { label: '5 - Far Exceeded Expectations', value: 5 },
  { label: '4 - Exceeded Expectations', value: 4 },
  { label: '3 - Somewhat Exceeded Expectations', value: 3 },
  { label: '2 - Met Expectations', value: 2 },
  { label: '1 - Below Expectations', value: 1 },
];

export const selfReviewTemplates: IReflectionQuestionSet[] = [
  {
    token: 'rqs_blackwell_2024_07_self',
    name: '[Blackwell] Self Reflection',
    questions: [
      {
        token: 'q_blackwell_2024_07_self_impact',
        type: QuestionType.OPEN_ENDED,
        text: "Which tasks or projects that you've contributed to have had the greatest impact towards achieving our team's goals in the last six months, and why?",
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_self_collab',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide an example where you collaborated with your peers outside of your function and describe how it contributed to securing Blackwell or our customers.',
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_self_projects',
        type: QuestionType.OPEN_ENDED,
        text: "What new responsibilities or projects would you be interested in taking on to help drive Blackwell's growth and success?",
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_self_support',
        type: QuestionType.OPEN_ENDED,
        text: 'What, if anything, can your manager do to support you better?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_agrovision_2024_07_self',
    name: '[Agrovision] Self Reflection',
    questions: [
      {
        token: 'q_agrovision_2024_06_self_great',
        type: QuestionType.OPEN_ENDED,
        text: 'What did you do great?',
        required: true,
      },
      {
        token: 'q_agrovision_2024_06_self_work',
        type: QuestionType.OPEN_ENDED,
        text: 'What could you work on?',
        required: true,
      },
      {
        token: 'q_agrovision_2024_06_self_feedback',
        type: QuestionType.OPEN_ENDED,
        text: "What's some feedback you have for the company?",
        required: true,
      },
      {
        token: 'q_agrovision_2024_06_self_kpi',
        type: QuestionType.OPEN_ENDED,
        text: 'What KPIs can you propose for 2024-2025?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_madebymary_2024_06_h1_review',
    name: '[Made By Mary] H1 Review',
    questions: [
      {
        token: 'q_madebymary_2024_06_h1_rate',
        type: QuestionType.RATING,
        text: 'To what degree did you meet the expectations of your role for this review cycle?',
        scale: [
          { label: 'Below Expectations', value: 1 },
          { label: 'Meets Expectations', value: 2 },
          { label: 'Somewhat Exceeds Expectations', value: 3 },
          { label: 'Far Exceeds Expectations', value: 4 },
        ],
        includeValueInLabel: true,
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_explain',
        type: QuestionType.OPEN_ENDED,
        text: 'Please explain your rating.',
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_accomps',
        type: QuestionType.OPEN_ENDED,
        text: 'What accomplishments are you most proud of for this review cycle?',
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_improve',
        type: QuestionType.OPEN_ENDED,
        text: 'What area(s) would you most like to improve on in the coming months?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_kin_2024_06_5g_okr_retro',
    name: '[Kinfolk] 5G OKR Retrospective',
    questions: [
      {
        token: 'q_kin_2024_06_5g_okr_retro_good',
        type: QuestionType.OPEN_ENDED,
        text: `Good (What was good about this quarter?)
    • What part of the process did you play in the path towards good?
    • What were the biggest wins you witnessed?
    • What aspects were better than expected? why?`,
        required: true,
      },
      {
        token: 'q_kin_2024_06_5g_okr_retro_glum',
        type: QuestionType.OPEN_ENDED,
        text: `Glum (What made us feel blue?)
    • What could have been done differently to improve the experience?
    • What wasn't well-received about the last quarter?
    • What did you need that you didn't have to be successful in the last quarter?`,
        required: true,
      },

      {
        token: 'q_kin_2024_06_5g_okr_retro_grasped',
        type: QuestionType.OPEN_ENDED,
        text: `Grasped (What did we understand?)
    • What skills did you learn over the past quarter?
    • What did we learn about how we operated in the past to better inform our future?`,
        required: true,
      },
      {
        token: 'q_kin_2024_06_5g_okr_retro_forward',
        type: QuestionType.OPEN_ENDED,
        text: `Going Forward (What do we do next?)
    • How will you approach things differently?
    • What can we as an org to help you?`,
        required: true,
      },
      {
        token: 'q_kin_2024_06_5g_okr_retro_grateful',
        type: QuestionType.OPEN_ENDED,
        text: `Grateful (What are we grateful for?)`,
        required: true,
      },
    ],
  },
  {
    token: 'rqs_startout_2024_05_self_pilot',
    name: 'Pilot Self Review [StartOut]',
    questions: [
      {
        token: 'q_startout_2024_05_self_pilot_well',
        type: QuestionType.OPEN_ENDED,
        text: 'What is going well for you in your role and work at StartOut?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_self_pilot_accomps',
        type: QuestionType.OPEN_ENDED,
        text: 'What do you see as your top 3-5 accomplishments in this review period?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_self_pilot_challenge',
        type: QuestionType.OPEN_ENDED,
        text: 'What about your role and work at StartOut is most challenging right now?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_self_pilot_focus',
        type: QuestionType.OPEN_ENDED,
        text: 'What 1-2 areas do you want to focus on developing over the next cycle?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_self_pilot_fdbk_req',
        type: QuestionType.OPEN_ENDED,
        text: 'Are there any areas of your job that you would like to receive specific or additional feedback on from your direct manager?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_self_pilot_prompt',
        type: QuestionType.OPEN_ENDED,
        text: 'What questions or conversation prompts do you have that you would like to discuss during this conversation?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_keebo_h1_2024_perf_rev_self',
    name: 'H1 2024 Performance Self Review [Keebo]',
    questions: [
      {
        token: 'q_kbo_h1_2024_per_rev_self_expectations',
        type: QuestionType.RATING,
        text: 'How well do you feel you have met the expectations outlined for your role?',
        scale: [
          { label: 'Exceeded expectations', value: 5 },
          { label: 'Above expectations', value: 4 },
          { label: 'Met expectations', value: 3 },
          { label: 'Below expectations', value: 2 },
          { label: 'Not at all', value: 1 },
        ],
        required: true,
      },
      {
        token: 'q_kbo_h1_2024_per_rev_self_role',
        type: QuestionType.RATING,
        text: 'To what degree do you agree with this statement: "I have a clear understanding of what is expected of me for success in my role".',
        scale: [
          { label: 'Definitely agree', value: 5 },
          { label: 'Mostly agree', value: 4 },
          { label: 'Somewhat agree', value: 3 },
          { label: 'Slightly agree', value: 2 },
          { label: 'Disagree', value: 1 },
        ],
        required: true,
      },
      {
        token: 'q_kbo_h1_2024_per_rev_self_impact',
        type: QuestionType.OPEN_ENDED,
        text: "Which tasks or projects that you've contributed to have had the greatest impact towards achieving our team's goals in the last six months, and why?",
        required: true,
      },
      {
        token: 'q_kbo_h1_2024_per_rev_self_future',
        type: QuestionType.OPEN_ENDED,
        text: 'What do you want to accomplish in your career over the next 6 months? How do you want to stretch or learn?',
        required: true,
      },
      {
        token: 'q_kbo_h1_2024_per_rev_self_supp',
        type: QuestionType.OPEN_ENDED,
        text: 'What can your manager do to help you be more successful in your current role? How can they improve?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_ffix_2024_apr_self',
    name: 'April Self Reflection [FactoryFix]',
    questions: [
      {
        token: 'q_ffix_2024_apr_self_goals',
        type: QuestionType.OPEN_ENDED,
        text: 'How did you perform compared to goals and expectations? Consider your impact and the outcomes',
        required: true,
      },
      {
        token: 'q_ffix_2024_apr_self_focus',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should you focus on? Include areas of opportunity, goals for the upcoming quarter, etc',
        required: true,
      },
      {
        token: 'q_ffix_2024_apr_self_status',
        type: QuestionType.OPEN_ENDED,
        text: 'Discuss the current status of your personal and professional goals, addressing progress, obstacles encountered, and any additional steps to consider',
        required: false,
      },
      {
        token: 'q_ffix_2024_apr_self_else',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_ffix_2024_oct_self',
    name: 'October Self Reflection [FactoryFix]',
    questions: [
      {
        token: 'q_ffix_2024_oct_self_goals',
        type: QuestionType.OPEN_ENDED,
        text: 'How did you perform compared to goals and expectations? Consider your impact and the outcomes',
        required: true,
      },
      {
        token: 'q_ffix_2024_oct_self_focus',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should you focus on? Include areas of opportunity, goals for the upcoming quarter, etc',
        required: true,
      },
      {
        token: 'q_ffix_2024_oct_self_status',
        type: QuestionType.OPEN_ENDED,
        text: "What's your number one focus through the rest of 2024?",
        required: false,
      },
      {
        token: 'q_ffix_2024_oct_self_else',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_ffix_2024_aug_self',
    name: 'Self Reflection w/Motivators [FactoryFix]',
    questions: [
      {
        token: 'q_ffix_2024_aug_self_motivators',
        type: QuestionType.RANK,
        text: 'Please RANK the following factors based on how important they are for your motivation. The most important factor should be ranked #1 and the least important should be ranked #9.',
        rankingOptions: [
          {
            token: 'ro_motivators_responsibilities',
            label: 'Balance among work responsibilities',
            description: 'ro_motivators_responsibilities description',
          },
          {
            token: 'ro_motivators_worklife',
            label: 'Work-life balance',
            description: 'ro_motivators_worklife description',
          },
          {
            token: 'ro_motivators_pride',
            label: 'Institutional pride',
            description: 'ro_motivators_pride description',
          },
          {
            token: 'ro_motivators_self_actualization',
            label: 'Self-actualization at work',
            description: 'ro_motivators_self_actualization description',
          },
          {
            token: 'ro_motivators_job',
            label: 'Job content',
            description: 'ro_motivators_job description',
          },
          {
            token: 'ro_motivators_boss',
            label: 'Relationship with your boss',
            description: 'ro_motivators_boss description',
          },
          {
            token: 'ro_motivators_colleagues',
            label: 'Relationship with your colleagues',
            description: 'ro_motivators_colleagues description',
          },
          {
            token: 'ro_motivators_succeed',
            label: 'Being in the position to succeed at your job',
            description: 'ro_motivators_succeed description',
          },
          {
            token: 'ro_motivators_compensation',
            label: 'Financial compensation',
            description: 'ro_motivators_compensation description',
          },
        ],
        required: true,
      },
      {
        token: 'q_ffix_2024_aug_self_went_well',
        type: QuestionType.OPEN_ENDED,
        text: 'What went well over the past 6 weeks?',
        required: true,
      },
      {
        token: 'q_ffix_2024_aug_self_gone_better',
        type: QuestionType.OPEN_ENDED,
        text: 'What could have gone better over the past 6 weeks?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_keebo_self_h1',
    name: 'H1 2024 Self Reflection [Keebo]',
    questions: [
      {
        token: 'q_keebo_self_instructions',
        type: QuestionType.LABEL,
        text: 'This process is meant to promote meaningful discussions around performance and expectations for you in your role.\n\nFor this exercise, create a few bullet points that outline how you would describe what success looks like for you in your current role. We anticipate some misalignment and acknowledge that expectations may not match perfectly. Your manager will do the same and will result in a live conversation where you will align for the future.',
      },
      {
        token: 'q_keebo_2024_self_h1_q1',
        type: QuestionType.OPEN_ENDED,
        text: 'What are some examples of success in your role?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_self',
    name: 'Basic Self Review',
    questions: [
      {
        token: 'q_self_reflection_expectations',
        type: QuestionType.OPEN_ENDED,
        text: 'How did you perform compared to your goals and expectations? Consider your impact and the outcomes',
        required: true,
      },
      {
        token: 'q_self_reflection_progress',
        type: QuestionType.OPEN_ENDED,
        text: 'What growth areas do you want to focus on?',
        required: true,
      },
      {
        token: 'q_reflection_additional_thoughts',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide any additional thoughts',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_ffix_self_2',
    name: 'Self Review',
    questions: [
      {
        token: 'q_2024_02_ffix_perf',
        type: QuestionType.OPEN_ENDED,
        text: 'How did you perform compared to goals and expectations? Consider your impact and the outcomes',
        required: true,
      },
      {
        token: 'q_2024_02_ffix_focus',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should you focus on? Include areas of opportunity, goals for the upcoming quarter, etc',
        required: true,
      },
      {
        token: 'q_2024_02_ffix_goal_status',
        type: QuestionType.OPEN_ENDED,
        text: 'Discuss the current status of your personal and professional goals, addressing progress, obstacles encountered, and any additional steps to consider',
        required: false,
      },
      {
        token: 'q_2024_02_ffix_else',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_provivi_self',
    name: 'Self Review [Provivi]',
    questions: [
      {
        token: 'q_provivi_self_intro',
        type: QuestionType.LABEL,
        text: "In the coming weeks, you'll be completing your Annual Review Cycle assessment. The first step for you is to spend some time with the following questions, answering them to the best of your ability. This exercise is for you just as much as it is for your reviewer. The more details you're able to capture, the more helpful this experience will be.\n\nOnce you finish your self-assessment and submit it, your manager will have a chance to review, add their perspective, and schedule a call to walk through it all together.",
      },
      {
        token: 'q_self_provivi_achievements1',
        type: QuestionType.OPEN_ENDED,
        text: "What are the key accomplishments you are most proud of in the past review period? How did these contribute to the team or company's objectives?",
        required: true,
      },
      {
        token: 'q_self_provivi_challenges1',
        type: QuestionType.OPEN_ENDED,
        text: 'What were the most significant challenges or struggles you faced during this period? How did you address them, and what did you learn?',
        required: true,
      },
      {
        token: 'q_self_provivi_development1',
        type: QuestionType.OPEN_ENDED,
        text: 'Based on your experiences this period, what skills or knowledge areas do you feel you need to develop or improve?',
        required: true,
      },
      {
        token: 'q_self_provivi_growth1',
        type: QuestionType.OPEN_ENDED,
        text: "Looking ahead, what are new areas or projects you're interested in exploring to enhance your professional growth?",
        required: true,
      },
      {
        token: 'q_self_provivi_goals1',
        type: QuestionType.OPEN_ENDED,
        text: "What are your professional goals for the next review period? How do these align with our team or company's objectives?",
        required: true,
      },
      {
        token: 'q_self_provivi_reflections1',
        type: QuestionType.OPEN_ENDED,
        text: 'Are there any specific actions your manager or the company can take to better support your performance and engagement at Provivi?',
        required: true,
      },
      {
        token: 'q_self_provivi_conclusion1',
        type: QuestionType.RATING,
        scale: ProviviPerformanceScale,
        text: 'How would you rate your performance during the last review period?',
        required: true,
        includeValueInLabel: false,
      },
    ],
  },
];

export const managerReviewTemplates: IReflectionQuestionSet[] = [
  {
    token: 'rqs_blackwell_2024_07_mgr',
    name: '[Blackwell] Manager Reflection',
    questions: [
      {
        token: 'q_blackwell_2024_07_mgr_impact',
        type: QuestionType.OPEN_ENDED,
        text: "Which tasks or projects that {{firstName}} contributed to have had the greatest impact towards achieving our team's goals in the last six months, and why?",
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_mgr_collab',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide an example where {{firstName}} collaborated with their peers outside of their function and describe how it contributed to securing Blackwell or our customers.',
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_mgr_projects',
        type: QuestionType.OPEN_ENDED,
        text: "What new responsibilities or projects should {{firstName}} consider taking on to help drive Blackwell's growth and success?",
        required: true,
      },
      {
        token: 'q_blackwell_2024_07_mgr_thank',
        type: QuestionType.OPEN_ENDED,
        text: 'What should we thank {{firstName}} for?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_agrovision_2024_07_mgr',
    name: '[Agrovision] Manager Reflection',
    questions: [
      {
        token: 'q_agrovision_2024_07_mgr_kpis',
        type: QuestionType.RATING,
        text: 'How well did they perform against their KPIs?',
        scale: createScale([
          'Extremely Poorly',
          'Very Poorly',
          'Poorly',
          'Moderately Well',
          'Well',
          'Very Well',
          'Exceptionally Well',
        ]),
        includeValueInLabel: false,
        required: true,
      },
    ],
  },
  {
    token: 'rqs_madebymary_2024_06_mgr_h1_review',
    name: '[Made By Mary] H1 Review',
    questions: [
      {
        token: 'q_madebymary_2024_06_h1_mgr_rate',
        type: QuestionType.RATING,
        text: 'To what degree did this person meet the expectations of their role?',
        scale: [
          { label: 'Below Expectations', value: 1 },
          { label: 'Meets Expectations', value: 2 },
          { label: 'Somewhat Exceeds Expectations', value: 3 },
          { label: 'Far Exceeds Expectations', value: 4 },
        ],
        includeValueInLabel: true,
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_mgr_examples',
        type: QuestionType.OPEN_ENDED,
        text: 'Please explain your rating.',
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_mgr_impact',
        type: QuestionType.OPEN_ENDED,
        text: 'In what areas has this individual had the greatest impact on you, your team, or the company?',
        required: true,
      },
      {
        token: 'q_madebymary_2024_06_h1_mgr_potential',
        type: QuestionType.OPEN_ENDED,
        text: 'In what areas do you see the most potential for this person? How do you hope to see this person grow over the next 3-6 months?',
        required: true,
      },
    ],
  },
  {
    token: 'rqs_startout_2024_05_mgr_pilot',
    name: 'Pilot Manager Review [StartOut]',
    questions: [
      {
        token: 'q_startout_2024_05_mgr_pilot_impact',
        type: QuestionType.OPEN_ENDED,
        text: 'What are the top 2-3 most impactful contributions from the review period?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_mgr_pilot_power',
        type: QuestionType.OPEN_ENDED,
        text: "What is {{firstName}}'s superpower? (List 1-2 of the team member's standout areas of strength)",
        required: true,
      },
      {
        token: 'q_startout_2024_05_mgr_pilot_continue',
        type: QuestionType.OPEN_ENDED,
        text: 'What are 1-2 things that {{firstName}} should keep doing for continued success?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_mgr_pilot_growth',
        type: QuestionType.OPEN_ENDED,
        text: 'What are 1-2 areas where {{firstName}} needs improvement and/or shows opportunity for growth?',
        required: true,
      },
      {
        token: 'q_startout_2024_05_mgr_pilot_prompt',
        type: QuestionType.OPEN_ENDED,
        text: 'What questions or conversation prompts do you have that you would like to discuss during this conversation? ',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_keebo_h1_2024_perf_rev_mgr',
    name: 'H1 2024 Performance Manager Review [Keebo]',
    questions: [
      {
        token: 'q_kbo_h1_2024_per_rev_mgr_expectations',
        type: QuestionType.RATING,
        text: "How would you rate {{firstName}}'s performance? Select one rating for your team member.",
        scale: [
          { label: 'Far exceeding expectations', value: 5 },
          { label: 'Above expectations', value: 4 },
          { label: 'Meeting expectations', value: 3 },
          {
            label: 'Meeting expectations with areas for improvement',
            value: 2,
          },
          { label: 'Below expectations', value: 1 },
        ],
        required: true,
      },

      {
        token: 'q_kbo_h1_2024_per_rev_mgr_impact',
        type: QuestionType.OPEN_ENDED,
        text: 'What positive impact has {{firstName}} had on the team and business goals?',
        required: true,
      },
      {
        token: 'q_kbo_h1_2024_per_rev_mgr_growth',
        type: QuestionType.OPEN_ENDED,
        text: "Considering {{firstName}}'s performance, where do you see room for growth in their role? What steps can they consider taking to improve?",
        required: true,
      },
    ],
  },
  {
    token: 'rqs_ffix_2024_apr_mgr',
    name: 'April Manager Reflection [FactoryFix]',
    questions: [
      {
        token: 'q_ffix_2024_apr_mgr_goals',
        type: QuestionType.OPEN_ENDED,
        text: 'How did {{firstName}} perform compared to goals and expectations? Consider the impact and the outcomes',
        required: true,
      },
      {
        token: 'q_ffix_2024_apr_mgr_focus',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should {{firstName}} focus on? Include areas of opportunity, goals for the upcoming quarter, etc',
        required: true,
      },
      {
        token: 'q_ffix_2024_apr_mgr_status',
        type: QuestionType.OPEN_ENDED,
        text: "Discuss the current status of {{firstName}}'s personal and professional goals, addressing progress, obstacles encountered, and any additional steps to consider",
        required: false,
      },
      {
        token: 'q_ffix_2024_apr_mgr_else',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_keebo_mgr_h1',
    name: 'H1 2024 Manager Reflection [Keebo]',
    questions: [
      {
        token: 'q_keebo_mgr_instructions',
        type: QuestionType.LABEL,
        text: 'This process is meant to promote meaningful discussions around performance and expectations for your team member in their role.\n\nFor this exercise, create a few bullet points that outline how you would describe what success looks like for your team member in their current role. We anticipate some misalignment and acknowledge that expectations may not match perfectly. This exercise will also be completed by your team member and will result in a live conversation where you will align for the future.',
      },
      {
        token: 'q_keebo_2024_mgr_h1_q1',
        type: QuestionType.OPEN_ENDED,
        text: 'What are some examples of success in their role?',
        required: true,
        counterpartQuestionToken: 'q_keebo_2024_self_h1_q1',
      },
    ],
  },
  {
    token: 'rqs_manager',
    name: 'Basic Manager Review',
    questions: [
      {
        token: 'q_reflection_expectations',
        type: QuestionType.OPEN_ENDED,
        text: 'How did they perform compared to goals and expectations? Consider their impact and the outcomes',
        required: true,
      },
      {
        token: 'q_reflection_progress',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should they focus on?',
        required: true,
      },
      {
        token: 'q_reflection_additional_thoughts',
        type: QuestionType.OPEN_ENDED,
        text: 'Provide any additional thoughts',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_ffix_mgr_2',
    name: 'Manager Review',
    questions: [
      {
        token: 'q_2024_02_mgr_ffix_perf',
        type: QuestionType.OPEN_ENDED,
        text: 'How did they perform compared to goals and expectations? Consider the impact and the outcomes',
        required: true,
        counterpartQuestionToken: 'q_2024_02_ffix_perf',
      },
      {
        token: 'q_2024_02_ffix_mgr_focus_area',
        type: QuestionType.OPEN_ENDED,
        text: 'What areas should they focus on? Include areas of opportunity, goals for the upcoming quarter, etc',
        required: true,
        counterpartQuestionToken: 'q_2024_02_ffix_focus',
      },
      {
        token: 'q_2024_02_ffix_mgr_goal_status',
        type: QuestionType.OPEN_ENDED,
        text: 'Discuss the current status of their personal and professional goals, addressing progress, obstacles encountered, and any additional steps to consider',
        required: false,
        counterpartQuestionToken: 'q_2024_02_ffix_goal_status',
      },
      {
        token: 'q_2024_02_ffix_mgr_else',
        type: QuestionType.OPEN_ENDED,
        text: 'Anything else?',
        required: false,
      },
    ],
  },
  {
    token: 'rqs_provivi_manager',
    name: 'Manager Review [Provivi]',
    questions: [
      {
        token: 'q_provivi_mgr_intro',
        type: QuestionType.LABEL,
        text: "Now that self-assessments are complete, the next step of the ARC process begins for you as a manager. Flint will provide access to your direct report's self-assessments. Take a few minutes to review their answers.\n\nIn your manager review questionnaire, focus on celebrating your team's achievements, acknowledging their struggles, and add ideas to how you can support their self-identified growth needs. Beyond this, your role is also to act as a guide, identifying blind spots and pointing out important insights they may have missed.\n\nOnce your review is complete and submitted, you'll be prompted to schedule a 1:1 call to walk through your feedback together with your direct report.",
      },
      {
        token: 'q_mgr_provivi_achievements1',
        type: QuestionType.OPEN_ENDED,
        text: "What are the key accomplishments {{firstName}} should be most proud of in the past review period? How did these contribute to the team or company's objectives?",
        required: true,
      },
      {
        token: 'q_mgr_provivi_challenges1',
        type: QuestionType.OPEN_ENDED,
        text: 'What were the most significant challenges or struggles you saw {{firstName}} face during this period? What did you learn about them?',
        required: true,
      },
      {
        token: 'q_mgr_provivi_development1',
        type: QuestionType.OPEN_ENDED,
        text: 'Based on your experiences this period, what skills or knowledge areas do you feel {{firstName}} most needs to develop or improve?',
        required: true,
      },
      {
        token: 'q_mgr_provivi_growth1',
        type: QuestionType.OPEN_ENDED,
        text: "Looking ahead, what are new areas or projects you're interested in seeing {{firstName}} explore to enhance their professional growth?",
        required: true,
      },
      {
        token: 'q_mgr_provivi_goals1',
        type: QuestionType.OPEN_ENDED,
        text: "What professional goals would you like to see {{firstName}} accomplish during the next review period? How do these align with our team or company's objectives?",
        required: true,
      },
      {
        token: 'q_mgr_provivi_reflections1',
        type: QuestionType.OPEN_ENDED,
        text: 'Are there any specific actions you or the company can take to better support {{firstName}}’s performance and engagement at Provivi?',
        required: true,
      },
      {
        token: 'q_mgr_provivi_conclusion1',
        text: 'How would you rate {{firstName}}’s performance during the last review period?',
        type: QuestionType.RATING,
        scale: ProviviPerformanceScale,
        includeValueInLabel: false,
        required: true,
      },
    ],
  },
];

export const reflectionTemplates: IReflectionQuestionSet[] = [
  {
    token: 'rqs_ffix_self',
    name: 'Manager Review',
    questions: [
      {
        token: 'q_2024_01_ffix_well_2023',
        type: QuestionType.OPEN_ENDED,
        text: 'What went well in 2023? Highlight any specific accomplishments, wins, goals achieved, etc',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_go_better',
        type: QuestionType.OPEN_ENDED,
        text: 'What could be going better? Highlight any specific areas you are working on, work not accomplished, goals not met, etc.',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_goals_2024',
        type: QuestionType.OPEN_ENDED,
        text: 'What are your goals/priorities for 2024?',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_goals',
        type: QuestionType.OPEN_ENDED,
        text: 'What are your personal and professional goals? Talk about the status of each, progress made, roadblocks, and what you need to move these goals forward.',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_mgr_supp',
        type: QuestionType.OPEN_ENDED,
        text: 'What support do you need from your manager to achieve those goals?',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_else',
        type: QuestionType.OPEN_ENDED,
        text: "Anything else you'd like to share?",
        required: false,
      },
    ],
  },
  {
    token: 'rqs_ffix_mgr',
    name: 'Manager Review',
    questions: [
      {
        token: 'q_2024_01_ffix_well_2023',
        type: QuestionType.OPEN_ENDED,
        text: 'What went well in 2023? Highlight any specific accomplishments, wins, goals achieved, etc',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_mgr_go_better',
        type: QuestionType.OPEN_ENDED,
        text: 'What could be going better? Highlight any specific areas this person needs to work on, work not accomplished, goals not met, etc.',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_mgr_goals_2024',
        type: QuestionType.OPEN_ENDED,
        text: "What are this person's goals/priorities for 2024?",
        required: true,
      },
      {
        token: 'q_2024_01_ffix_mgr_development',
        type: QuestionType.OPEN_ENDED,
        text: 'How would you like to see this person grow? Use this space to highlight any personal/professional development goals or opportunities, as well as any areas they should focus on over the next quarter.',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_mgr_thank_person',
        type: QuestionType.OPEN_ENDED,
        text: 'What should we thank this person for?',
        required: true,
      },
      {
        token: 'q_2024_01_ffix_else',
        type: QuestionType.OPEN_ENDED,
        text: "Anything else you'd like to share?",
        required: false,
      },
    ],
  },
  ...managerReviewTemplates,
  ...selfReviewTemplates,
];

export const isManagerReflection = (reflection: IReflection) =>
  reflection.authorToken !== reflection.receiverToken;
